import React from 'react'

import styled, { css } from 'styled-components'

import { Container, Angle, Link, Section, SectionTitle } from './styles'

import { Row, Col } from 'react-styled-flexboxgrid'

const imageStyle = css`
    max-width: 60%;
    display: block;
    height: auto;
    margin: 1rem auto;
    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin: 1rem 0 1rem auto;
    }
`

const Text = styled.p`
    line-height: 150%;
    font-weight: 300;
    margin-bottom: 1rem;
`

const CtaLink = styled(Link)`
    margin-bottom: 1rem;
`

const ButtonContainer = styled.div`
    text-align: center;
    margin: 2rem 0 3rem 0;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        text-align: right;
    }
`
export default ({ cta, angle, image, text, button, title, ...rest }) => {
    return (
        <Section angle={angle} {...rest}>
            <Container>
                <Row middle={'xs'}>
                    <Col xs={12} md={6}>
                        <SectionTitle>{title}</SectionTitle>
                        <Text>{text}</Text>
                        {cta && <CtaLink to={cta.link}>{cta.text}</CtaLink>}
                    </Col>
                    <Col xs={12} md={6}>
                        {image && image({ css: imageStyle })}
                        <ButtonContainer>{button}</ButtonContainer>
                    </Col>
                </Row>
            </Container>
            {angle && <Angle />}
        </Section>
    )
}
