import React from 'react'

import Layout from '../components/layout'

import SEO from '../components/seo'

import Banner from '../components/banner'

import Header from '../components/header'

import Section from '../components/home-section'

import Wallet from '../images/wallet.svg'

import Contact from '../images/contacts.svg'

import Eco from '../images/eco.svg'

import Verified from '../images/verified.svg'

import EasyToUse from '../images/easy-to-use.svg'

import Button from '../components/button'

import Footer from '../components/footer'

const sections = [
    {
        image: ({ css }) => <Wallet css={css} />,
        title: "Save money, it's free",
        text: `Never pay for a business card again. never need to dispose a
business card when details change or you loose a team
member. Save money and time by getting a verified business
card today.`,
        cta: {
            text: 'Create free digital business card',
            link: '/create-business-card',
        },
    },
    {
        image: ({ css }) => <Contact css={css} />,
        title: 'Never lose a contact again',
        text: `Do you often misplace business cards, not any more. Remember
                  clients and vendors also used to misplace yours, now they
                    can reach without needing to hold bags of paper or enjoy
                    loads of data entry or data auditing if they are using a
                    scanner.
                `,
        cta: {
            text: 'Keep your contacts in one place',
            link: '/create-business-card',
        },
    },
    {
        image: ({ css }) => <EasyToUse css={css} />,
        title: 'Easy to use',
        text: `3 steps, 4 minutes, to build, then the world is yours to
                    prospect
                `,
        cta: {
            text: 'Start now',
            link: '/create-business-card',
        },
    },
    {
        image: ({ css }) => <Verified css={css} />,
        title: 'Secure and Verified',
        text: `Get your details verified, protect your companies name and
                    domain from misuse, stop your ex-employees from using their
                    old business cards. Get connected securely and get the extra
                    boost of trust you need when prospecting. Isn’t it Verifeco.
                `,
        cta: {
            text: 'Protect your business',
            link: '/verify-your-business',
        },
    },
    {
        image: ({ css }) => <Eco css={css} />,
        title: 'Paperless Eco Friendly',
        text: `Save the trees, save the water from ink waste, save the
                    world. You can even donate the money you used to pay for
                    business cards to children in need, or use it to plant more
                    trees. Are you aware how many business cards are printed in
                    the world daily. That is forests dead daily, and ink waste
                    and chemicals hurting our water supplies. And how many are
                    thrown. All this waist needs to end, just get a verified and
                    eco friendly business card today with VerifEco.
                `,
        cta: {
            text: 'Get green business card',
            link: '/create-business-card',
        },
    },
    {
        title: 'Get your business verified',
        text: `Manage all business cards at one place. No body else can use your company name.`,
        button: (
            <Button to={'/verify-your-business'}>
                Verify your business now
            </Button>
        ),
    },
]

const IndexPage = () => (
    <Layout>
        <SEO
            title="Home"
            description="Providing verified and eco-friendly solutions for the world"
        />
        <Header mode="site-header" />
        <Banner />
        {sections.map((section, i) => {
            return (
                <Section
                    key={i}
                    alternate={i % 2 === 0}
                    {...section}
                    last={i === sections.length - 1}
                />
            )
        })}
        <Footer />
    </Layout>
)

export default IndexPage
