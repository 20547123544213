import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { useTransition, animated } from 'react-spring'

import Button from './button'

import { Row, Col } from 'react-styled-flexboxgrid'

import { Container, Angle } from './styles'

const BannerSection = styled.section`
    margin-bottom: 1rem;
    position: relative;
    z-index: 100;
    text-align: center;
`

const Title = styled.h1`
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
    color: ${props => props.theme.black};
    margin-bottom: 4rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-bottom: 6rem;
        text-align: left;
    }
`

const Subtitle = animated(styled.h3`
    font-weight: 300;
    color: ${props => props.theme.black};
    margin-top: 0.5rem;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 10rem;
    font-size: 1.2rem;
    padding: 0 0.5rem;

    @media (min-width: 400px) {
        top: 10rem;
    }
    @media (min-width: 415px) {
        top: 8.5rem;
    }
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
        top: 8rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
        top: 8rem;
        text-align: left;
        left: initial;
        right: initial;
    }
`)

const CTAButton = styled(Button)`
    margin: 2rem 0 2.5rem;
`

const CTAButtonContainer = styled.div`
    text-align: center;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
        text-align: right;
    }
`

export const TITLES = [
    'No download required, go online in seconds',
    'Save money – get your business card free today.',
    'Never lose a contact again!',
    'Easy to use.',
    'Secure and Verified.',
    'Paper less - eco friendly.',
]

const config = { mass: 5, tension: 1000, friction: 200 }

export default () => {
    const [titles, setTitles] = useState([TITLES[0]])

    const transitions = useTransition(titles, t => TITLES.indexOf(t), {
        config,
        from: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
        },

        leave: { opacity: 0 },
    })

    useEffect(() => {
        setInterval(function() {
            setTitles(previous => {
                const prevIndex = TITLES.indexOf(previous[0])
                const nextIndex =
                    prevIndex === TITLES.length - 1 ? 0 : prevIndex + 1

                return [TITLES[nextIndex]]
            })
        }, 3000)
    }, [])

    return (
        <BannerSection>
            <Container>
                <Row middle={'xs'}>
                    <Col xs={12} md={7}>
                        <Title>
                            The new standard in exchanging business cards
                        </Title>
                        {transitions.map(({ item, props, key }) => {
                            return (
                                <Subtitle key={key} style={props}>
                                    {item}
                                </Subtitle>
                            )
                        })}
                    </Col>
                    <Col xs={12} md={5}>
                        <CTAButtonContainer>
                            <CTAButton to="/create-business-card">
                                Get your business card
                            </CTAButton>
                        </CTAButtonContainer>
                    </Col>
                </Row>
            </Container>
            <Angle />
        </BannerSection>
    )
}
